import { RouteRecordRaw } from "vue-router";

const routes: RouteRecordRaw[] = [
  {
    path: "/mentions-legales",
    name: "legal-notice",
    component: () => import("./LegalNotice.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/cgu",
    name: "cgu",
    component: () => import("./CGU.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/confidentialites",
    name: "confidentialities",
    component: () => import("./ConfidentialitiesPolicy.vue"),
    meta: { requiresAuth: false },
  },
];

export default routes;
