<template>
  <header
    :class="{
      'scrolled-nav': scrolledNav,
      'displayed-nav': isMenuDisplayed && !scrolledNav,
    }"
  >
    <nav>
      <Logo :scroll="scrolledNav" />
      <div class="right" v-if="isMobile">
        <div v-if="features.connection == 'true'" class="sign-in">
          <router-link :to="{ name: '' }"
            ><i class="fa fa-user"></i
          ></router-link>
        </div>
        <div @click="toggleNav" class="icon">
          <i
            style="font-size: 1.5rem"
            :class="{
              'fa fa-bars': !isMenuDisplayed,
              'fa fa-user': isMenuDisplayed,
            }"
          ></i>
        </div>
      </div>
      <div v-else>
        <Menu :scroll="scrolledNav" />
      </div>
      <div v-if="isMobile && isMenuDisplayed" class="dropdown-nav">
        <ul>
          <li>
            <router-link class="link" :to="{ name: 'home' }"
              >Accueil</router-link
            >

            <router-link class="link" :to="{ name: 'services' }"
              >Services</router-link
            >

            <router-link class="link" :to="{ name: 'contact' }"
              >Contact</router-link
            >

            <router-link class="link" :to="{ name: 'appointment' }"
              >Réservation</router-link
            >
          </li>
        </ul>
      </div>
      <div
        v-if="!isMobile && features.connection == 'true'"
        class="cta-connection"
      >
        <router-link v-if="isConnected" class="cta-connected" :to="{ name: '' }"
          ><i class="'fa fa-user'"></i
        ></router-link>
        <router-link v-else class="cta" :to="{ name: '' }"
          >Connexion</router-link
        >
      </div>
    </nav>
  </header>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

import Logo from "./components/Logo.vue";
import Menu from "./components/Menu.vue";
import { features } from "@/config";

export default defineComponent({
  name: "NavBar",

  components: {
    Logo,
    Menu,
  },

  data() {
    return {
      features,
      isMobile: false,
      isMenuDisplayed: false,
      scrolledNav: false,
      windowWidth: null,
    };
  },
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  computed: {
    ...mapGetters({
      isConnected: "user/isConnected",
    }),
  },
  methods: {
    toggleNav() {
      this.isMenuDisplayed = !this.isMenuDisplayed;
    },
    updateScroll() {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 50) {
        this.scrolledNav = true;
        return;
      }
      this.scrolledNav = false;
      return;
    },
    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 900) {
        this.isMobile = true;
        return;
      }
      this.isMobile = false;
      this.isMenuDisplayed = false;
      return;
    },
  },
});
</script>

<style lang="stylus" scoped>
header
  position: fixed
  z-index: 999
  top: 0
  width: 100%
  box-sizing: border-box
  padding: 10px 5%

  nav
    display: flex
    justify-content: space-between
    align-items: center

    .right
      display: flex
      flex-direction: row
      gap: 1rem
      padding-right: 1rem;

    .cta
      font-size $fontS
      color $white
      padding $spacingXS $spacingL
      border-radius: 20px;
      background-color $secondary
      text-decoration: none;
      text-transform: uppercase

      &:hover {
        background-color: $secondaryLight
        color: $secondary
        }

      .cta-connected
        background-color: $secondary
        color: white
        border-radius: 50%;
        padding: 8px 10px;

    .dropdown-nav
      position: absolute
      width: 100%
      top: 80%
      left: 0
      background-color: $lightGrey
      border-bottom: 1px solid $grey;
      max-height: auto
      overflow: hidden

      ul
        display:table;

      li
        display: flex
        flex-direction: column
        padding: 8px 0
        width: auto
        gap: 1rem
        a
          color: $grey
          text-transform: uppercase
          text-decoration: none
          &:hover
            color: $secondary
            font-weight: bold
  .scrolled-nav
    background-color: $grey
    .dropdown-nav
        background-color: $grey
        border-bottom: 1px solid $white;

    .link
      color: $white
      &:hover {
        color: $primary;
      }

    .cta
      background-color: $primary
      &:hover {
        background-color: $white
        color: $primary
        border-color: $primary
      }

      @keyframes fade {
        from {
          max-height: 0;
        }
        to {
          max-height: 500px; /* Adjust the maximum height as needed */
        }
      }
.displayed-nav
  background-color: $lightGrey
</style>
