import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CookieBanner = _resolveComponent("CookieBanner")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.showBanner)
      ? (_openBlock(), _createBlock(_component_CookieBanner, {
          key: 0,
          onAcceptCookies: _ctx.acceptCookies
        }, null, 8, ["onAcceptCookies"]))
      : _createCommentVNode("", true)
  ]))
}