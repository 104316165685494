
import { defineComponent } from "vue";

export default defineComponent({
  name: "Button",
  props: {
    isGrey: {
      type: Boolean,
      default: false,
    },
    isBlue: {
      type: Boolean,
      default: false,
    },
    isBlueLight: {
      type: Boolean,
      default: false,
    },
    isTransparent: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selectionned: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
