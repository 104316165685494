import { UserType } from "@/types/User";
import { FormAddVehicule } from "@/views/appointment/store/type";

export enum SignupSteps {
  PREAMBLE = "PREAMBLE",
  STATUS = "STATUS",
  CONTACT = "CONTACT",
  LOGIN = "LOGIN",
  ENGIN = "ENGIN",
  VALIDATION = "VALIDATION",
}

export interface LoginForm {
  email: string;
  password: string;
}

export interface LoginDataResponse {
  access_token: string;
}

export interface SignupForm {
  firstName: string;
  lastName: string;
  usageName?: string;
  address: string;
  addressAdditional?: string;
  city: string;
  postalCode: string;
  phone: string;
  email: string;
  password: string;
  userType: UserType;
  raison?: string;
  siret?: string;
  vehicules?: FormAddVehicule[];
}
