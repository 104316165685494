import { RootState } from "@/store";
import { Cookies } from "@/types/Cookies";
import { User } from "@/types/User";
import { useCookies } from "vue3-cookies";
import { GetterTree, ActionTree, MutationTree } from "vuex";
import { MUTATION_TYPES } from "./mutation-types";
import { axios } from "@/libs/axios";
import {
  isAdminUser,
  isParticularUser,
  isProfessionalUser,
} from "@/types/UserTypeGuards";

export interface UserState {
  user: User | Record<string, never>; // Same as User | {}
}

const state = (): UserState => ({
  user: {},
});

const getters: GetterTree<UserState, RootState> = {
  getUser: (state: UserState) => state.user,
  isConnected: (state: UserState) => Object.keys(state.user).length > 0,
  isParticular: (state: UserState) => isParticularUser(state.user),
  isProfessional: (state: UserState) => isProfessionalUser(state.user),
  isAdmin: (state: UserState) => isAdminUser(state.user),

  getDashboardUrl: (state: UserState) => {
    if (isParticularUser(state.user) || isProfessionalUser(state.user))
      return "/mon-compte";
    if (isAdminUser(state.user)) return "/dashboard";
    else return "/";
  },
};

const actions: ActionTree<UserState, RootState> = {
  async fetchUser({ commit }) {
    const { cookies } = useCookies();

    if (cookies.get(Cookies.CONNECTION_TOKEN)) {
      const { data: user } = await axios.get<User>(`/auth/profile`);
      commit(MUTATION_TYPES.SET_USER, user);
    }
  },
};

const mutations: MutationTree<UserState> = {
  [MUTATION_TYPES.SET_USER](state: UserState, payload: User) {
    state.user = payload;
  },
};

const namespaced = true;

export default { state, getters, actions, mutations, namespaced };
