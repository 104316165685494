
import CookieBanner from "./CookieBanner.vue";
import { defineComponent } from "vue";
import { useCookies } from "vue3-cookies";
import { Cookies } from "@/types/Cookies";

const { cookies } = useCookies();

export default defineComponent({
  name: "RGPDBanner",

  components: { CookieBanner },

  data() {
    return {
      forceHideBanner: false,
    };
  },

  computed: {
    showBanner(): boolean {
      return !cookies.get(Cookies.GDPR) && !this.forceHideBanner;
    },
  },

  methods: {
    acceptCookies() {
      cookies.set(Cookies.GDPR, "true", "365d");
      this.forceHideBanner = true;
    },
  },
});
