import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/overview/landingPage/LayoutView.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/services",
    name: "services",
    component: () => import("../views/overview/PrestationsSection.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/overview/ContactSection.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/reservation-en-ligne",
    name: "appointment",
    component: () => import("../views/overview/AppointmentSection.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("../views/PageNotFound.vue"),
    meta: { requiresAuth: false },
  },
];

export default routes;
