//import { MUTATION_TYPES } from "./mutation-types";
import { RootState } from "@/store";
import { GetterTree, ActionTree, MutationTree } from "vuex";

export interface VehiculeState {
  //   currentStep: SignupSteps;
  //   status: UserType;
  //   user: User | Record<string, never>;
  //   userProfessional: UserProfessional | Record<string, never>;
}

//Les variables /  par défaut -> pas syncronisé avec les vues
const state = (): VehiculeState => ({});

// Permet d'accéder au state -> syncronisé avec le changement
const getters: GetterTree<VehiculeState, RootState> = {};

// asynchrones -> fonction généralistes dans les actions qui peuvent appeler à la fin des mutations
const actions: ActionTree<VehiculeState, RootState> = {};

//Modifie les variables du state, syncronisé avec l'état, déclenche une action vis à vis de cette variable
//Payload = structure sur laquelle va se charger nos données, on peut le mettre quand il y a un objet
const mutations: MutationTree<VehiculeState> = {};

const namespaced = true;

export default { state, getters, actions, mutations, namespaced };
