import { createStore } from "vuex";
import connection, {
  ConnectionState,
} from "@/views/connection/store/connection";
import vehicule, { VehiculeState } from "@/views/appointment/store/vehicule";
import contact, { ContactState } from "@/views/overview/store/contact";
import user, { UserState } from "@/views/user/store/user";

export interface RootState {
  contact: ContactState;
  connection: ConnectionState;
  vehicule: VehiculeState;
  user: UserState;
}

export default createStore<RootState>({
  modules: {
    connection,
    vehicule,
    contact,
    user,
  },
});
