import {
  AdminUser,
  ParticularUser,
  ProfessionalUser,
  User,
  UserType,
} from "./User";

export function isParticularUser(
  user: User | Record<string, never>
): user is ParticularUser {
  if (user == {}) return false;
  return user.user_type === UserType.PARTICULAR;
}

export function isProfessionalUser(
  user: User | Record<string, never>
): user is ProfessionalUser {
  if (user == {}) return false;
  return user.user_type === UserType.PROFESSIONAL;
}

export function isAdminUser(
  user: User | Record<string, never>
): user is AdminUser {
  if (user === {}) return false;

  return user.user_type === UserType.ADMIN;
}
