<template>
  <NavBar />
  <router-view />
  <FooterLayout />
  <RGPDBanner />
</template>

<script>
import { defineComponent, reactive, computed } from "vue";
import { useRoute } from "vue-router";
import { useSeo } from "@/libs/seo";
import { useHead } from "@unhead/vue";
import NavBar from "@/components/layouts/Navigation.vue";
import FooterLayout from "@/components/layouts/FooterLayout.vue";
import RGPDBanner from "@/components/gdpr/RGPDBanner.vue";

export default defineComponent({
  name: "App",
  setup() {
    const route = reactive(useRoute());
    const seo = computed(() => useSeo(route));

    useHead({
      title: computed(() => seo.value.title),
      meta: [
        {
          name: "description",
          content: computed(() => seo.value.description),
        },
      ],
    });
  },
  components: {
    NavBar,
    FooterLayout,
    RGPDBanner,
  },
});
</script>

<style lang="stylus">
@import './theme.styl'

html, body
  padding: 0
  margin: 0
  font-family $textFont
  color $textColor
  scroll-behavior: smooth;

#app
  height 100vh
  font-family $textFont
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
</style>
