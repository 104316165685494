
import { defineComponent } from "vue";

export default defineComponent({
  name: "Logo",

  props: {
    scroll: {
      type: Boolean,
      default: false,
    },
  },
});
