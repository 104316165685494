export const config = {
  apiUrl: process.env.VUE_APP_API_URL,
  baseUrl: process.env.BASE_URL,
  environment: process.env.VUE_APP_ENV,
  timeout: process.env.VUE_APP_TIMEOUT,
};

interface Features {
  connection: boolean;
}

export const features: Features = {
  connection: process.env.VUE_APP_FF_CONNECTION,
};
