export enum MUTATION_TYPES {
  NEXT_STEP = "NEXT_STEP",
  PREVIOUS_STEP = "PREVIOUS_STEP",
  INITIALIZE_STEPPER = "INITIALIZE_STEPPER",
  CHANGE_STEP = "CHANGE_STEP",
  SET_USER_TYPE = "SET_USER_TYPE",
  SET_USER_PROFESSIONAL_INFORMATIONS = "SET_USER_PROFESSIONAL_INFORMATIONS",
  SET_USER_PERSONNAL_INFORMATIONS = "SET_USER_PERSONNAL_INFORMATIONS",
  SET_USER_LOGIN = "SET_USER_LOGIN",
  SET_USER_VEHICULE = "SET_USER_VEHICULE",
  REMOVE_USER_VEHICULE = "REMOVE_USER_VEHICULE",
  SET_USER = "SET_USER",
  SET_USER_INFORMATIONS_EMPTY = "SET_USER_INFORMATIONS_EMPTY",
}
