import { RouteLocationNormalizedLoaded } from "vue-router";
import { seoData, SeoPage } from "./seo";

const BASE_TITLE = "Garage FFR";

export const useSeo = (route: RouteLocationNormalizedLoaded): SeoPage => {
  const path = route.path;

  const seo = seoData[path];
  if (!seo) return seoData.default;

  return {
    ...seo,
    title: `${BASE_TITLE} - ${seo.title}`,
  };
};
