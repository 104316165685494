import { Vehicule } from "./Vehicule";

export enum UserType {
  PARTICULAR = "particular",
  PROFESSIONAL = "professional",
  ADMIN = "admin",
}

interface BaseUser {
  id: number;
  first_name: string;
  last_name: string;
  usage_name?: string;
  address_1: string;
  address_2?: string;
  city: string;
  postal_code: number;
  phone: number;
  email: string;
  password: string;
  vehicules: Vehicule[];
  created_at: Date;
  updated_at: Date;
}

export interface ParticularUser extends BaseUser {
  user_type: UserType.PARTICULAR;
}

export interface ProfessionalUser extends BaseUser {
  raison?: string;
  siret?: string;
  user_type: UserType.PROFESSIONAL;
}

export interface AdminUser extends BaseUser {
  user_type: UserType.ADMIN;
}

export type User = ParticularUser | ProfessionalUser | AdminUser;
