import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", {
    class: _normalizeClass(["navigation", {
      'scrolled-nav': _ctx.scroll,
    }])
  }, [
    _createVNode(_component_router_link, {
      class: "link",
      to: { name: 'home' }
    }, {
      default: _withCtx(() => [
        _createTextVNode("Accueil")
      ]),
      _: 1
    }),
    _createVNode(_component_router_link, {
      class: "link",
      to: { name: 'services' }
    }, {
      default: _withCtx(() => [
        _createTextVNode("Services")
      ]),
      _: 1
    }),
    _createVNode(_component_router_link, {
      class: "link",
      to: { name: 'contact' }
    }, {
      default: _withCtx(() => [
        _createTextVNode("Contact")
      ]),
      _: 1
    }),
    _createVNode(_component_router_link, {
      class: "link",
      to: { name: 'appointment' }
    }, {
      default: _withCtx(() => [
        _createTextVNode("Réservation")
      ]),
      _: 1
    })
  ], 2))
}