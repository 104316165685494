export interface SeoPage {
  title: string;
  description: string;
}

export interface SeoPages {
  [key: string]: SeoPage;
}

export const seoData = {
  "/": {
    title: "Services de Réparation Automobile de Qualité à Saint-Etienne",
    description:
      "Votre garage automobile de confiance à Saint-Etienne (42). Nos mécaniciens formés en réparation et entretien vous offrent des services de qualité pour maintenir votre véhicule en parfait état. Prenez rendez-vous dès aujourd'hui pour une expérience sans tracas.",
  },
  "/services": {
    title: "Nos services",
    description:
      "Consulter notre gamme complète de services pour répondre à tous vos besoins en matière d'entretien et de réparation automobile (pneus, vidange, pare-brise, géométrie, etc).",
  },
  "/contact": {
    title: "Nous contacter",
    description:
      "Contactez notre garage automobile FFR situé à Saint-Etienne (42) pour des services de réparation, d'entretien et de diagnostic de qualité.",
  },
  "/réservation-en-ligne": {
    title: "Réservation",
    description:
      "Réservez facilement vos services de réparation et d'entretien automobile en ligne chez FFR garage Saint-Etienne. Planifiez votre rendez-vous dès aujourd'hui pour une expérience sans tracas et des solutions professionnelles pour votre véhicule.",
  },
  default: {
    title: "Services de Réparation Automobile de Qualité",
    description:
      "Votre garage automobile FFR de confiance à Saint-Etienne (42). Nos mécaniciens formés en réparation et entretien vous offrent des services de qualité pour maintenir votre véhicule en parfait état. Prenez rendez-vous dès aujourd'hui pour une expérience sans tracas.",
  },
} as SeoPages;
