import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/loaders/loader_linear.svg'


const _withScopeId = n => (_pushScopeId("data-v-9495c67a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled", "selectionned"]
const _hoisted_2 = {
  key: 0,
  src: _imports_0,
  alt: "loader_linear"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", _mergeProps({
    class: ["button", {
      grey: _ctx.isGrey,
      blue: _ctx.isBlue,
      whiteblue: _ctx.isBlueLight,
      transparent: _ctx.isTransparent,
      selectionned: _ctx.selectionned,
      disabled: _ctx.disabled,
    }],
    disabled: _ctx.disabled,
    selectionned: _ctx.selectionned
  }, _ctx.$attrs), [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("img", _hoisted_2))
      : _renderSlot(_ctx.$slots, "default", { key: 1 }, undefined, true)
  ], 16, _hoisted_1))
}