import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/logo/logo_clair_sans_slogan.svg'
import _imports_1 from '@/assets/logo/logo_fonce_sans_slogan.svg'


const _withScopeId = n => (_pushScopeId("data-v-74a57485"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "logo" }
const _hoisted_2 = {
  key: 0,
  src: _imports_0,
  alt: "Logo FFR"
}
const _hoisted_3 = {
  key: 1,
  src: _imports_1,
  alt: "Logo FFR"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, { to: { name: 'home' } }, {
      default: _withCtx(() => [
        (!_ctx.scroll)
          ? (_openBlock(), _createElementBlock("img", _hoisted_2))
          : (_openBlock(), _createElementBlock("img", _hoisted_3))
      ]),
      _: 1
    })
  ]))
}