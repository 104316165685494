<template>
  <footer class="footer">
    <div class="row">
      <div class="footer-col">
        <img
          v-if="!scroll"
          src="@/assets/logo/logo_fonce_sans_slogan.svg"
          alt="Logo FFR"
          style="width: 13rem"
        />
      </div>
      <div class="footer-col">
        <h2>Plan du site</h2>
        <ul>
          <li>
            <router-link class="link" :to="{ name: 'home' }"
              >Accueil</router-link
            >
            <router-link class="link" :to="{ name: 'services' }"
              >Services</router-link
            >
            <router-link class="link" :to="{ name: 'contact' }"
              >Contact</router-link
            >
            <router-link class="link" :to="{ name: 'appointment' }"
              >Réservation en ligne</router-link
            >
          </li>
        </ul>
      </div>
      <div class="footer-col">
        <h2>Légales</h2>
        <ul>
          <li>
            <router-link class="link" :to="{ name: 'legal-notice' }"
              >Mentions légales</router-link
            >
            <router-link class="link" :to="{ name: 'cgu' }"
              >Conditions générales d'utilisation</router-link
            >
            <router-link class="link" :to="{ name: 'confidentialities' }"
              >Politique de confidentialité</router-link
            >
          </li>
        </ul>
      </div>
      <div class="footer-col">
        <h2>Suivez-nous !</h2>
        <div class="social-links">
          <a
            href="https://www.facebook.com/FFR.FizaineFamilyRacing/"
            target="_blank"
            ><i class="fab fa-facebook-f"></i
          ></a>
          <a
            href="https://www.instagram.com/fizainefamilyracing_ig/"
            target="_blank"
            ><i class="fab fa-instagram"></i
          ></a>
        </div>
      </div>
    </div>
    <div class="copyright">
      <p>FFR © {{ new Date().getFullYear() }}</p>
    </div>
  </footer>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "FooterLayout",
});
</script>

<style lang="stylus" scoped>
  *
    margin:0;
    padding:0;
    box-sizing: border-box;

.footer
  background-color: $grey
  border: red
  .row
    display: flex
    flex-wrap: wrap
    justify-content: space-around;
    padding: 2%


    .footer-col
      padding: 1rem
      ul
        list-style: none
      h2
        text-transform: capitalize;
        margin-bottom: 1rem;
        position: relative;
        font-size: 1.3rem
        color: $white
      a
        text-transform: capitalize;
        font-size: 1.1rem
        padding: 0.5rem 0
        color: white;
        text-decoration: none;
        display: block;
        &:hover {
          color: $primary
        }

    .social-links
      a
        display: inline-block
        height: 40px;
        width: 40px;
        background-color: rgba(255,255,255,0.2);
        margin:0 10px 10px 0;
        text-align: center;
        line-height: 40px;
        border-radius: 50%;
        transition: all 0.5s ease;
        padding: 0
        &:hover{
          text-decoration: none;
          background-color: $primary
          color: white
          }

  .copyright
    display: flex
    justify-content: center;
    padding: 2%
    border-top solid 1px #5a5656

    p
      color: white
      font-weight: bold

@media(max-width: $mobileBreakpoint)
  .row
    flex-direction: column
</style>
