import { User, UserType } from "@/types/User";
import { MUTATION_TYPES } from "./mutation-types";
import { LoginDataResponse, LoginForm, SignupForm, SignupSteps } from "./type";
import { GetterTree, ActionTree, MutationTree, ActionContext } from "vuex";
import { FormAddVehicule } from "@/views/appointment/store/type";
import { RootState } from "@/store";
import { axios, getAxiosError } from "@/libs/axios";
import { router as $router, router } from "@/main";
import { useCookies } from "vue3-cookies";
import { Cookies } from "@/types/Cookies";

export interface ConnectionState {
  currentStep: SignupSteps;
  user: SignupForm | Record<string, never>;
  vehicules: FormAddVehicule[] | any;
}

const stepsOrder = [
  SignupSteps.PREAMBLE,
  SignupSteps.STATUS,
  SignupSteps.CONTACT,
  SignupSteps.LOGIN,
  SignupSteps.ENGIN,
  SignupSteps.VALIDATION,
];

const userTypeProspect = [
  UserType.ADMIN,
  UserType.PARTICULAR,
  UserType.PROFESSIONAL,
];

const state = (): ConnectionState => ({
  currentStep: SignupSteps.PREAMBLE,
  user: {},
  vehicules: [],
});

const getters: GetterTree<ConnectionState, RootState> = {
  getCurrentStep: (state: ConnectionState) => state.currentStep,
  isStepCompleted: (state: ConnectionState) => (step: SignupSteps) =>
    stepsOrder.indexOf(step) < stepsOrder.indexOf(state.currentStep),
  isLastStep: (state: ConnectionState) =>
    stepsOrder.indexOf(state.currentStep) === stepsOrder.length - 1,
  isFirstStep: (state: ConnectionState) =>
    stepsOrder.indexOf(state.currentStep) === 0,
  getCarsList: (state: ConnectionState) => state.vehicules,
  getSignupFormInformations: (state: ConnectionState) => state.user,
};

const actions: ActionTree<ConnectionState, RootState> = {
  addNewCar({ commit }, payload) {
    commit(MUTATION_TYPES.SET_USER_VEHICULE, payload);
  },
  async login(
    { rootGetters, dispatch }: ActionContext<ConnectionState, RootState>,
    { email, password }: LoginForm
  ) {
    try {
      const { data }: { data: LoginDataResponse } = await axios.post(
        "/auth/login",
        {
          email,
          password,
        }
      );
      if (data) {
        const { cookies } = useCookies();

        cookies.set(Cookies.CONNECTION_TOKEN, data.access_token, "7d");
      }
      await dispatch("user/fetchUser", null, { root: true });

      router.push(rootGetters["user/getDashboardUrl"]);
    } catch (e: any) {
      const { message } = getAxiosError(e);

      if (message.match(/Wrong Credentials/gi)) {
        throw "Email ou mot de passe incorrect.";
      }
      throw "Une erreur s'est produite";
    }
  },
  async signup({ commit, state }: ActionContext<ConnectionState, RootState>) {
    try {
      await axios.post("/users/signup", {
        firstName: state.user.firstName,
        lastName: state.user.lastName,
        usageName: state.user.usageName,
        address: state.user.address,
        city: state.user.city,
        postalCode: state.user.postalCode,
        phone: state.user.phone,
        email: state.user.email,
        password: state.user.password,
        userType: state.user.userType,
        raison: state.user.raison,
        siret: state.user.siret,
        vehicules: state.vehicules,
      });
    } catch (e: any) {
      const { message } = getAxiosError(e);
      console.log(message);

      if (message.match(/existing/gi)) {
        throw "Cet utilisateur existe déjà. Vous pouvez essayer de vous connecter sur la page connexion";
      }
      if (message.match(/blacklisted/gi)) {
        throw "Impossible d'utiliser des emails jetables";
      }

      throw "Une erreur s'est produite";
    }

    commit(MUTATION_TYPES.SET_USER_INFORMATIONS_EMPTY);
  },
  initializeStep({ commit }) {
    const routeName = router.currentRoute.value.name;

    if (routeName === "Status")
      commit(MUTATION_TYPES.CHANGE_STEP, SignupSteps.STATUS);
    else if (routeName === "ContactInformations")
      commit(MUTATION_TYPES.CHANGE_STEP, SignupSteps.CONTACT);
    else if (routeName === "Identifiants")
      commit(MUTATION_TYPES.CHANGE_STEP, SignupSteps.LOGIN);
    else if (routeName === "VehiculeInformations")
      commit(MUTATION_TYPES.CHANGE_STEP, SignupSteps.ENGIN);
    else if (routeName === "Validation")
      commit(MUTATION_TYPES.CHANGE_STEP, SignupSteps.VALIDATION);
    else commit(MUTATION_TYPES.CHANGE_STEP, SignupSteps.PREAMBLE);
  },
};

const mutations: MutationTree<ConnectionState> = {
  [MUTATION_TYPES.INITIALIZE_STEPPER](state: ConnectionState) {
    state.currentStep = stepsOrder[0];
  },
  [MUTATION_TYPES.CHANGE_STEP](state, step) {
    state.currentStep = step;
  },
  [MUTATION_TYPES.NEXT_STEP](state: ConnectionState) {
    state.currentStep = stepsOrder[stepsOrder.indexOf(state.currentStep) + 1];
  },
  [MUTATION_TYPES.PREVIOUS_STEP](state: ConnectionState) {
    state.currentStep = stepsOrder[stepsOrder.indexOf(state.currentStep) - 1];
  },
  [MUTATION_TYPES.SET_USER_TYPE](state: ConnectionState, userType: UserType) {
    state.user.userType = userTypeProspect[userTypeProspect.indexOf(userType)];
  },
  [MUTATION_TYPES.SET_USER_PROFESSIONAL_INFORMATIONS](
    state: ConnectionState,
    payload: SignupForm
  ) {
    state.user.siret = payload.siret;
    state.user.raison = payload.raison;
  },
  [MUTATION_TYPES.SET_USER_PERSONNAL_INFORMATIONS](
    state: ConnectionState,
    payload: SignupForm
  ) {
    state.user.firstName = payload.firstName;
    state.user.lastName = payload.lastName;
    state.user.usageName = payload.usageName;
    state.user.address = payload.address;
    state.user.city = payload.city;
    state.user.postalCode = payload.postalCode;
    state.user.phone = payload.phone;
  },
  [MUTATION_TYPES.SET_USER_VEHICULE](
    state: ConnectionState,
    payload: FormAddVehicule[]
  ) {
    state.vehicules = [...state.vehicules, payload];
  },
  [MUTATION_TYPES.REMOVE_USER_VEHICULE](state: ConnectionState, payload: any) {
    state.vehicules.splice(payload, 1);
  },

  [MUTATION_TYPES.SET_USER_LOGIN](state: ConnectionState, payload: LoginForm) {
    state.user.email = payload.email;
    state.user.password = payload.password;
  },
  [MUTATION_TYPES.SET_USER_INFORMATIONS_EMPTY](state: ConnectionState) {
    state.user.firstName = "";
    state.user.lastName = "";
    state.user.usageName = "";
    state.user.address = "";
    state.user.city = "";
    state.user.postalCode = "";
    state.user.phone = "";
    state.user.siret = "";
    state.user.raison = "";
    state.user.userType = UserType.PARTICULAR;
  },
};

const namespaced = true;

export default { state, getters, actions, mutations, namespaced };
