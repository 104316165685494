
import { defineComponent } from "vue";
import Button from "@/components/forms/Button.vue";

export default defineComponent({
  name: "CookieBanner",

  components: { Button },

  methods: {
    acceptCookies() {
      this.$emit("acceptCookies");
    },
  },
});
