import { createApp } from "vue";
import App from "./App.vue";
import { createRouter, createWebHistory } from "vue-router";
import store from "./store";

import defaultRoutes from "@/router";
import connection from "./views/connection/routes";
import customer from "./views/user/customer/routes";
import admin from "./views/user/admin/routes";
import legal from "./views/legals/routes";

/*ElementPlus*/
import "element-plus/dist/index.css";
import ElementPlus from "element-plus";
import fr from "element-plus/es/locale/lang/fr";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

import "v-calendar/style.css";
import { setupCalendar } from "v-calendar";

import { useCookies } from "vue3-cookies";
import { UserType } from "./types/User";
import { Cookies } from "./types/Cookies";
import { createHead } from "@unhead/vue";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [...defaultRoutes, ...legal],
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const requiresAuthentification = to.matched.some(
    (record) => record.meta.requiresAuth
  );

  //Je récupère le type d'utilisateur
  const isParticular = store.getters["user/isParticular"];
  const isProfessional = store.getters["user/isProfessional"];
  const isAdmin = store.getters["user/isAdmin"];

  const routeTypeParticular = to.matched.some((record) =>
    record.meta.userTypeAuthorized?.find(
      (element) => element === UserType.PARTICULAR
    )
  );
  const routeTypeProfessional = to.matched.some((record) =>
    record.meta.userTypeAuthorized?.find(
      (element) => element === UserType.PROFESSIONAL
    )
  );
  const routeTypeAdmin = to.matched.some((record) =>
    record.meta.userTypeAuthorized?.find(
      (element) => element === UserType.ADMIN
    )
  );
  const { cookies } = useCookies();
  if (cookies.get(Cookies.CONNECTION_TOKEN)) {
    await store.dispatch("user/fetchUser");
  }
  const isConnected = store.getters["user/isConnected"];

  if (!requiresAuthentification) return next();
  else {
    if (!isConnected) {
      return next("/connexion");
    } else {
      if (
        (isParticular && routeTypeParticular) ||
        (isProfessional && routeTypeProfessional) ||
        (isAdmin && routeTypeAdmin)
      ) {
        return next();
      } else {
        return next(store.getters["user/getDashboardUrl"]);
      }
    }
  }
});

const app = createApp(App);
const head = createHead();

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app
  .use(router)
  .use(head)
  .use(store)
  .use(ElementPlus, {
    locale: fr,
  })
  .use(setupCalendar, {})
  .mount("#app");

export { router };
